// import Translator from "../components/translator";

/**
 *
 * @param {string} type
 * @returns HTMLElement
 */
const createElement = (type) => document.createElement(type);

/**
 *
 * @param {string} selector
 * @returns HTMLElement
 */
const select = (selector) => document.querySelector(selector);

/**
 *
 * @param {string} selector
 * @returns HTMLElement
 */
const selectAll = (selector) => document.querySelectorAll(selector);

/**
 * Get body element
 */
const body = select("body");

/**
 * Get icon type depending on icon name
 *
 * @param {string} name
 * @param {string} description
 * @returns HTMLElement
 */
const iconsType = (
  { icon, content, place, color, color_front, color_outline, players } = {
    color: "#000000",
  }
) => {
  // invert color
  const colorIconCenter = color ? invertHex(color) : color;

  // console.log(players);

  const dataPlayer =
    icon === "icon-flights"
      ? `data-id-players="${players}" data-id-flightnumber-map="${content}"`
      : "";

  // global class for icon
  const classGlobal =
    "d-flex h-100 w-100 justify-content-center align-items-center rounded-circle fw-bold";

  // console.log(color_outline);
  const colorBackground = color ? ` background-color: ${color};` : "";
  const colorFront = color_front ? ` color: ${color_front};` : "";
  const colorOutline = color_outline
    ? ` border: 2px solid ${color_outline};`
    : "";

  // const styleInline =
  //   colorIconCenter && color
  //     ? `style="${colorBackground}${colorFront}${colorOutline}`
  //     : "";

  const styleInlineForSVG = `style="${colorBackground}${colorFront}${colorOutline}"`;

  // icon HTML type
  const iconHTML = `<span class="${classGlobal}" ${styleInlineForSVG}>${content}</span>`;

  const iconColor = icon === "icon-flights" ? "#ffffff" : color_front;

  const iconFlightsFavorite =
    icon === "icon-flights"
      ? `<use href="/images/icons/sprite.svg#${icon}-fave" class="favorite"></use>`
      : "";

  // icon SVG type
  const iconSVG = `
    <div ${dataPlayer} class="${classGlobal}" ${styleInlineForSVG}>
      <svg fill="${iconColor || "#000"}">
        <use href="/images/icons/sprite.svg#${icon}" class="first"></use>
        ${iconFlightsFavorite}
      </svg>
    </div>`;

  // set icon type
  const iconType =
    icon === "icon-restpoint" ||
    (icon === "icon-hole" && !place) ||
    icon === "icon-current"
      ? iconHTML
      : iconSVG;

  return iconType;
};

/**
 * Convert first letter to uppercase
 *
 * @param {Stirng} string
 * @returns HTMLElement
 */
const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Retirn a row for auto complete
 *
 * @param {object} objectArrays
 * @returns
 */
const showInformationAboutPlacesRow = (objectArrays) => {
  const defaultsRows = [
    {
      id: 99,
      name: "Show all layers",
      icon: "icon-show-all-layers",
      color: null,
      description: "show-all-layers",
      children: [],
    },
    {
      id: 0,
      name: "Groups",
      icon: "icon-flights",
      color: "#ff0000",
      description: "groups",
      children: [],
    },
  ];

  // console.log(objectArrays);
  const newArrayObject = [...defaultsRows, ...objectArrays];

  // console.log("function[show-array]", newArrayObject);

  return newArrayObject
    .map(({ id, icon, icon_shortcode, name, children }, index) => {
      let layerName = icon?.replace(/icon-/g, "");

      // if (children[0]) {
      let { color, color_front, color_outline } = children[0] || {};

      color = color || "";
      color_front = color_front || "";
      color_outline = color_outline || "";

      // children id
      const childrenID = children.map((child) => child.id).sort();

      const childrenObj = children[0];
      let colorBackground = childrenObj?.color;
      colorBackground = colorBackground ? colorBackground : null;

      let template = "";

      const switcher = `
        <div class="switch-container">
          <label class="switch" for="checkbox">
            <input type="checkbox" name="checkbox-switcher" class="checkbox-switcher" id="checkbox-${id}" />
            <div class="swich-slider round"></div>
          </label>
        </div>
      `;

      // create row text
      let removeNumberFromName =
        icon === "icon-hole" ? name.replace(/[0-9]/g, "") : name;

      // set the color of the "Group" icon in "information",
      // if there is no--color - group variable, set the color to red
      const colorForFlights =
        icon === "icon-flights" ? "var(--color-group, #ff0000)" : color;
      // icon === "icon-flights" ? "#ff0000" : getColor(children);

      // set layer id
      const layerId = index > 1 ? `${layerName}-${id}` : layerName;

      // console.log("function", layerId);

      // set type of icon
      const iconType =
        icon === "icon-restpoint"
          ? infoRowIcon(
              icon,
              icon_shortcode,
              colorForFlights,
              color_front,
              color_outline
            )
          : infoRowIcon(icon, layerName, colorForFlights, color_front);

      // console.log(iconType);

      const trackingData = `${icon}, ${removeNumberFromName
        .toLowerCase()
        .trim()}`;

      template += `
        <div data-tracking="${trackingData}" class="d-flex align-items-center information-row" data-layer-id="${layerId}" data-children="${childrenID}">
          ${iconType}
          ${infoRowName(capitalize(removeNumberFromName))}
          ${switcher}
        </div>`;

      return template;
    })
    .join("");
};

/**
 * Get description from children - information sidebar
 *
 * @param {array} children
 * @returns
 */
const getDescription = (children) => {
  if (children.length <= 0) return;

  return children
    .map((item) => (item.description !== "" ? item.description : ""))
    .filter((item) => item !== "")
    .join("");
};

/**
 * Gget color from children - information sidebar
 *
 * @param {array} children
 * @returns
 */
const getColor = (children) => {
  if (children.length <= 0) return;

  const test = children
    .map(({ color }) => (color ? color : ""))
    .filter((item) => item);

  const removeDuplicateColors = [...new Set(test)];

  return removeDuplicateColors.length > 0
    ? removeDuplicateColors[0]
    : "#ffffff";
};

/**
 * Clear stirng for i18n replace all spaces
 * as well as other characters not allowed
 *
 * @param {string} string
 * @returns
 */
const clearei18n = (string) => {
  return string
    .trim()
    .replace(/[^a-zA-Z ]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

/**
 * Set information row - information sidebar
 *
 * @param {string} layerName
 * @returns
 */
const infoRowName = (layerName) => {
  const i18nData = clearei18n(layerName);

  const i18JSON = HQ.Properties.i18n["information"][i18nData];

  const rowText = i18JSON ? i18JSON : layerName;

  return `<div class="information-row-name">${rowText}</div>`;
};

/**
 * Set icon - information sidebar
 *
 * @param {string} iconName
 * @param {string} layerName
 * @param {string} color
 * @returns
 */
const infoRowIcon = (iconName, layerName, color, color_front) => {
  return `<div class="icon ${iconName} information-row-icon">${iconsType({
    icon: iconName,
    content: layerName,
    place: "information",
    color,
    color_front,
  })}</div>`;
};

// --------------------------------------------------
// clear remove active-slider, information-is-active, players-is-active

/**
 * Clear active slider and remove active class
 */
const cleareActiveSlider = () => {
  const activeSliderClassName = "active-slider";

  const activeSlider = select(`.${activeSliderClassName}`);
  activeSlider?.classList.remove(activeSliderClassName);

  const sliders = [
    "information-is-active",
    "players-is-active",
    "leaderboard-is-active",
    "location-is-active",
    "highlights-is-active",
  ];

  sliders.forEach((slider) => {
    body.classList.remove(slider);
  });
};

/**
 * Convert JSON to Array
 *
 * @param {object} json - json object
 * @returns Array
 */
const convertJSONToArray = (json) => {
  // Object.values(players);
  const array = [];
  for (let key in json) {
    array.push(json[key]);
  }
  return array;
};

/**
 * Check is object empty
 *
 * @param {object} obj
 * @returns
 */
const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Set property to root html element
 *
 * @param {HTMLElement} root
 * @param {string} name
 * @param {string} height
 * @returns
 */
const setPropertyHeight = (root, name, height) => {
  return root.style.setProperty(name, height);
};

/**
 * Get item from localStorage
 *
 * @param {string} nameItem
 * @returns
 */
const getItemLocalStorage = (nameItem) =>
  JSON.parse(localStorage.getItem(nameItem));

/**
 * Set item to localStorage
 *
 * @param {string} nameItem
 * @param {string} value
 * @returns
 */
const setItemLocalStorage = (nameItem, value) =>
  localStorage.setItem(nameItem, JSON.stringify(value));

/**
 * Remove item from localStorage
 *
 * @param {string} nameItem
 * @returns
 */
const removeLocalStorage = (nameItem) => localStorage.removeItem(nameItem);

/**
 * Invert color
 *
 * @param {string} hexcolor
 * @returns
 */
const invertHex = (hexcolor) => {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.slice(0, 2), 16);
  const g = parseInt(hexcolor.slice(2, 4), 16);
  const b = parseInt(hexcolor.slice(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#ffffff";
};

/**
 * Count row in tbody
 *
 * @param {HTMLElement} table
 * @returns number
 */
const countRowInTable = (table) => table.querySelectorAll("tbody > tr").length;

/**
 * Count object inside array
 */
const countObjectIsideArray = (storage) => {
  return storage.reduce(
    (counter, { status }) => (status === "0" ? (counter += 1) : counter),
    0
  );
};

/**
 * Detect if device is mobile
 */
const hasTouchScreen = () => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

/**
 * Check if header exist
 */
const checkIfHeaderExist = () => {
  const checkHeader = select("#header");
  return checkHeader ? "54px" : 0;
};

/**
 *
 */
const checkApID = () => {
  const getAppID = getItemLocalStorage("HQappID");
  if (!getAppID) {
    console.log("getAppID", getAppID, HQ.Properties.appId);
    setItemLocalStorage("HQappID", HQ.Properties.appId);
  } else if (HQ.Properties.appId !== getAppID) {
    console.log("appId is different", HQ.Properties.appId);
    localStorage.clear();
    setItemLocalStorage("HQappID", HQ.Properties.appId);
  }
};

/**
 * Compare to date and return true if timeNow is bigger than time
 *
 * @param {string} time
 * @returns boolean
 */
const compareTwoTimes = (time) => {
  let curTime = new Date();
  curTime = parseInt(
    curTime.getHours() + "" + ("0" + curTime.getMinutes()).slice(-2)
  );

  return curTime > time.replace(":", "") ? true : false;
};

const markFavoritePlayers = (type) => {
  // get players from localstorage
  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  if (!getLocalStorage) return;

  const favoritePlayerOnMap = selectAll(".favorite-player-on-map");

  // remove all favorite player class
  favoritePlayerOnMap?.forEach((player) => {
    player.classList.remove("favorite-player-on-map");
  });

  if (getLocalStorage?.length <= 0) return;

  // console.log("favoritePlayerOnMap", favoritePlayerOnMap.length);

  // add to array all favorite player
  const allFavoritePlayers = [];
  getLocalStorage.forEach((obj) => {
    allFavoritePlayers.push(obj);
  });

  const getAllDataIdPlayers = selectAll("[data-id-players]");
  // console.log("getAllDataIdPlayers", getAllDataIdPlayers.length);

  // set favorite player class, default is red #ff0000
  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/71
  getAllDataIdPlayers.forEach((player, index) => {
    if (allFavoritePlayers.length <= 0) return;

    const arrayPlayers = player.dataset.idPlayers;

    const favoritePplayersArray = arrayPlayers.split(",").map(Number);

    allFavoritePlayers.forEach((fav) => {
      if (favoritePplayersArray.includes(fav)) {
        getAllDataIdPlayers[index].classList.add("favorite-player-on-map");
      }
    });
  });
};

/**
 * Get bounds from to points
 *
 * @param {object} maxBounds
 */
const getBounds = (maxBounds) => {
  // console.log(...maxBounds.southWest);
  // console.log(...maxBounds.northEast);
  const southWest = new L.LatLng(...HQ.Properties.maxBounds.southWest);
  const northEast = new L.LatLng(...HQ.Properties.maxBounds.northEast);
  return new L.LatLngBounds(southWest, northEast);
};

/**
 * Featch data from API
 *
 * @param {string} url to file
 * @returns
 */
async function fetchData(url) {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

/**
 * Return length of object
 * @returns {number}
 */
const objectLength = (object) => Object.keys(object).length;

/**
 * Translate text
 *
 * @param {string} lang
 * @returns
 */
const translator = (lang) => {
  const translator = new Translator({
    persist: false,
    languages: ["en", "de", "es"],
    // defaultLanguage: "en",
    detectLanguage: false,
    filesLocation: "/i18n",
  });

  return translator;
};

/**
 * Array equals
 *
 * @param {array} a
 * @param {array} b
 * @returns
 */
const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

/**
 * Check if var is undefined
 *
 * @param {string} varName
 * @returns
 */
const checkIfVarExist = (varName) => {
  return typeof varName === "undefined" ? false : true;
};

export {
  arrayEquals,
  body,
  checkApID,
  checkIfHeaderExist,
  checkIfVarExist,
  cleareActiveSlider,
  compareTwoTimes,
  convertJSONToArray,
  countObjectIsideArray,
  countRowInTable,
  createElement,
  fetchData,
  getBounds,
  getItemLocalStorage,
  hasTouchScreen,
  iconsType,
  isObjectEmpty,
  markFavoritePlayers,
  objectLength,
  removeLocalStorage,
  select,
  selectAll,
  setItemLocalStorage,
  setPropertyHeight,
  showInformationAboutPlacesRow,
};
