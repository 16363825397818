import { selectAll } from "../../utils/function";
import showPlacePopup from "../map/showPlacePopup";
import map from "../initialMap";

const activeInformation = ({ dataTwitsID, player }) => {
  const dataChildren = selectAll(".information-row");

  [...dataChildren].map((child) => {
    const childrenArray = child.dataset.children.split(",");
    const dataLayerId = child.dataset.layerId;

    const check = childrenArray.includes(dataTwitsID);

    // console.log("check", check);

    if (!check) return;

    const currentZoom = map.getZoom();

    showPlacePopup({
      placeID: dataTwitsID,
      idInformation: childrenArray[0],
      dataLayerId,
      currentZoom,
    });
  });
};

export default activeInformation;
