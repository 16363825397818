/**
 * Showing and hiding icons on the map
 * depending on the zoom level
 *
 * @param {number} currZoom
 */

const showHidePlacesOnMap = (currZoom) => {
  const curentZoomeLevel = Math.floor(currZoom);
  // console.log(currZoom);

  window["resultsPlaces"].map(({ id, icon, children }) => {
    let layerName = icon?.replace(/icon-/g, "");

    const layerNameWithId = `${layerName}-${id}`;

    // console.log(children);
    children.map(({ id, name, zoom_levels, area }) => {
      // console.log(id, name, zoom_levels);
      const zoomLevelsArray = zoom_levels;
      const zoomLevelsInArray = zoomLevelsArray.includes(curentZoomeLevel);

      if (window[`marker-${id}`] !== undefined) {
        if (zoomLevelsInArray) {
          const layerExistCheck = map.hasLayer(window[`marker-${id}`]);

          if (layerExistCheck) return;

          window[`marker-${id}`].addTo(window[layerNameWithId]);

          // check if area exist in place
          // if (area) {
          //   window[`area-${id}`].addTo(window[layerNameWithId]);
          // }
        }

        if (!zoomLevelsInArray) {
          const layerExistCheck = map.hasLayer(window[`marker-${id}`]);
          if (!layerExistCheck) return;

          window[layerNameWithId].removeLayer(window[`marker-${id}`]);

          // check if area exist in place
          // if (area) {
          //   window[layerNameWithId].removeLayer(window[`area-${id}`]);
          // }
        }
      }

      // check if area exist in place
      if (area) {
        // console.log(curentZoomeLevel);

        if (zoomLevelsInArray) {
          window[`area-${id}`].addTo(window[layerNameWithId]);
        }
        if (!zoomLevelsInArray) {
          window[layerNameWithId].removeLayer(window[`area-${id}`]);
        }
      }
    });
  });
};

export default showHidePlacesOnMap;
