import { select, createElement } from "../../utils/function";

// create siidebar menu
const mapContainer = select("#slider-wrapper");

const sidebar = createElement("div");
sidebar.className += "sidebar d-flex justify-content-between";

const buttonsitebar = [
  {
    icon: "info",
    i18n: "information",
    text: "Information",
  },
  {
    icon: "search",
    i18n: "player-search",
    text: "Player search",
  },
  {
    icon: "leaderboard",
    i18n: "leaderboard",
    text: "Leaderboard",
  },
  {
    icon: "media",
    i18n: "highlights",
    text: "Highlights",
  },
];

const fullScreenIn = {
  icon: "fullscreen-in",
  i18n: "fullscreen-in",
  text: "Fullscreen",
};

const buttonSitebar =
  HQ.Properties.mode === "iframe"
    ? [...buttonsitebar, fullScreenIn]
    : buttonsitebar;

const template = buttonSitebar
  .map(({ icon, i18n, text, title }) => {
    // console.log(i18n);
    return `
    <div class="menu-item flex-column justify-content-between flex-fill sidebar-${icon}">
      <svg><use href="/images/icons/sprite.svg#icon-${icon}"></use></svg>
      <div class="menu-text mt-1">${HQ.Properties.i18n.sidebar[i18n]}</div>
    </div>
  `;
  })
  .join("");

sidebar.insertAdjacentHTML("beforeend", template);

// set sidebar element after map
mapContainer.insertAdjacentElement("beforeend", sidebar);
