import { defaults } from "../../helpers/defaults";
import { hasTouchScreen } from "../../utils/function";

let { zoom, zoom_mobile } = defaults.coordinates;

const zoomDesktop = zoom;
const zoomMobile = zoom_mobile !== null ? zoom_mobile : zoom;

const zoomOnMobileOrDesktop = hasTouchScreen() ? zoomMobile : zoomDesktop;

export { zoomOnMobileOrDesktop };
