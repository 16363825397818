import map from "../initialMap";
import {
  getItemLocalStorage,
  setItemLocalStorage,
  select,
  removeLocalStorage,
} from "../../utils/function";

import { analyticsTracking } from "../tracking";

const information = "HQinformationSettings";

/**
 * Initialize information settings
 *
 * @param {Array} layers
 * @param {Array} checkboxes
 */
const informationSettings = (layers, checkboxes) => {
  // removeLocalStorage(information);
  const checkIfExistJSON = getItemLocalStorage(information);

  if (!checkIfExistJSON) {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });

    setNewLocalStorage(layers);
  } else {
    const checkIfExistJSON = getItemLocalStorage(information);

    // console.log("test", layers.length, checkIfExistJSON.length);

    if (layers.length !== checkIfExistJSON.length) {
      removeLocalStorage(information);
      setNewLocalStorage(layers);
    }

    checkIfExistJSON.forEach((item, index) => {
      try {
        const checkboxInput = select(`[data-layer-id="${item.layer}"] input`);
        checkboxInput.checked = item.checkbox ? true : false;
      } catch (error) {
        removeLocalStorage(information);
        setNewLocalStorage(layers);

        // const checkboxInput = select(`[data-layer-id="${item.layer}"] input`);
        // console.log(checkboxInput);
        // if (checkboxInput) {
        // checkboxInput.checked = item.checkbox ? true : false;
        // }
      }

      if (index === 0) return;

      let layer = window[item.layer];
      if (layer) {
        map[item.checkbox ? "addLayer" : "removeLayer"](layer);
      }
    });
  }
};

const setNewLocalStorage = (layers) => {
  const informationObject = [];
  layers.forEach((layer, index) => {
    informationObject.push({
      layer: layer.dataset.layerId,
      checkbox: true,
    });
  });

  setItemLocalStorage(information, informationObject);
};

/**
 * Update information settings
 *
 * @param {string} layer
 */
const informationSettingsUpdate = (layer, allLayers) => {
  // const getAllCheckboxes = selectAll(".switch > input");
  const showAllLayers = select(`[data-layer-id="${layer}"] input`);
  const dataTracking = select(`[data-layer-id="${layer}"]`).dataset.tracking;

  const [icon, text] = dataTracking.split(",");

  const eventType = showAllLayers.checked ? "activatePlace" : "deactivatePlace";

  analyticsTracking(eventType, {
    "icon-name": icon.replace("icon-", ""),
    title: text.trim(),
  });

  if (layer === "show-all-layers") {
    removeLocalStorage(information);
    setNewLocalStorage(allLayers);
  }

  const getDataFromLocalStorage = getItemLocalStorage(information);

  let newArrayObject;

  if (layer === "show-all-layers") {
    newArrayObject = getDataFromLocalStorage.map((obj) => ({
      ...obj,
      checkbox: showAllLayers.checked,
    }));
  } else {
    newArrayObject = getDataFromLocalStorage.map((obj) =>
      obj.layer === layer
        ? { ...obj, checkbox: obj.checkbox ? false : true }
        : obj
    );
  }

  const firstCheckbox = select(`[data-layer-id="show-all-layers"] input`);

  // count checkboxes withouth first element
  const countChecked = countCheckedCheckboxes(newArrayObject.slice(1));

  const updateFirstElement = (type) => {
    firstCheckbox.checked = type;
    return newArrayObject.map((obj) =>
      obj.layer === "show-all-layers" ? { ...obj, checkbox: type } : obj
    );
  };

  // update first checkbox
  if (newArrayObject.length - 1 === countChecked) {
    newArrayObject = updateFirstElement(true);
  }

  // update first checkbox
  if (newArrayObject.length - 1 > countChecked) {
    newArrayObject = updateFirstElement(false);
  }

  // update local storage
  setItemLocalStorage(information, newArrayObject);
};

/**
 * Count checked checkboxes
 *
 * @param {Array} checkboxes
 */
const countCheckedCheckboxes = (checkboxes) => {
  let count = 0;
  checkboxes.forEach((element) => {
    if (element.checkbox) {
      count++;
    }
  });
  return count;
};

export { informationSettings, informationSettingsUpdate };
