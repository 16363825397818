import { convertJSONToArray } from "../../utils/function";

let checkCurrentRound =
  typeof HQ.Properties.currentRound !== "undefined" ? true : false;

const showPlayersRow = (flight) => {
  let {
    matchnumber,
    status,
    currenthole,
    teetime,
    holesplayed,
    startingtee,
    matchnumber_str,
    players,
  } = flight;

  const {
    group,
    hole,
    par,
    players: player,
    pos,
    today,
    tee,
    thru,
  } = HQ.Properties.i18n["popup-content"];

  holesplayed = startingtee !== 1 ? `${holesplayed} *` : holesplayed;

  const rows = Object.entries(players)
    .map(([key, value]) => {
      let {
        id,
        totaltopar,
        totaltopar_str,
        proam,
        fullname,
        firstname,
        strokes,
        lastname,
        country,
        position,
      } = value;

      // console.log("value", value.fullname, value);

      if (proam) {
        proam = proam === "am" ? "(A)" : "";
      } else {
        proam = "";
      }

      const roundsCurrentRoundScore = convertJSONToArray(HQ.Properties.top5)
        .filter((item) => {
          return id === item.id;
        })
        .map((item) => {
          return item.rounds[HQ.Properties.currentRound]?.score;
        });

      let totaltoparData = totaltoparInformation(totaltopar, totaltopar_str);
      let todayColumn = checkCurrentRound
        ? +HQ.Properties.currentRound > 1
          ? roundsCurrentRoundScore
          : totaltoparData !== null
          ? totaltoparData
          : ""
        : "";

      // console.log(todayColumn);

      totaltopar = !totaltopar
        ? totaltopar_str
        : totaltopar < 0
        ? `<span class="text-danger">${totaltopar_str}</span>`
        : `<span class="text-success">${totaltopar_str}</span>`;

      return `
        <tr data-id-player=${id}>
          <th>${firstname.slice(0, 1)}. ${lastname} ${proam}</th>
          <td class="top-five-country">
            <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
          </td>
          <td class="text-center">${position || "-"}</td>
          ${
            +HQ.Properties.currentRound > 1
              ? `<td class="text-center">${todayColumn}</td>`
              : ""
          }
          <td class="text-center">${totaltopar}</td>
          <td class="text-center">${status !== "TEE" ? holesplayed : ""}</td>
        </tr>`;
    })
    .join("");

  return `
    <div class="popup-content">
      <h3><span>${group}</span> ${matchnumber}</h3>
      <div class="tee-time">
        <span>${tee}</span>: ${startingtee} ${teetime.slice(0, 5)}h
      </div>
      <div class="holes-played">
        <span>${hole}</span>: ${currenthole}
      </div>
      <table class="table table-borderless mt-1">
        <thead>
          <tr>
            <th scope="col">${player}</th>
            <th scope="col"></th>
            <th scope="col" class="text-center">${pos}</th>
             ${
               +HQ.Properties.currentRound > 1
                 ? `<th scope="col" class="text-center">${today}</th>`
                 : ""
             }
            <th scope="col" class="text-center">${par}</th>
            <th scope="col" class="text-center">${thru}</th>
          </tr>
        </thead>
        <tbody>
          ${rows}
        </tbody>
      </table>
    </div>`;
};

function totaltoparInformation(totaltopar, totaltopar_str) {
  return totaltopar
    ? totaltopar < 0
      ? `<span class="text-danger">${totaltopar_str}</span>`
      : `<span class="text-success">${totaltopar_str}</span>`
    : totaltopar_str;
}

export default showPlayersRow;
