import { select, createElement } from "../utils/function";

const popupCreate = () => {
  const popupInfo = createElement("div");
  popupInfo.classList.add("popup-info");
  document.body.insertAdjacentElement("beforeend", popupInfo);
};

/**
 * Create a popup with a message
 *
 * @param {string} text
 */
const popupShow = ({ title, text, time = "", type }) => {
  const popupInfo = select(".popup-info");

  popupInfo.textContent = "";

  const popupCloseButton = type
    ? `<button class="popup-close"><svg class="icon"><use href="/images/icons/sprite.svg#icon-close"></use></svg></button>`
    : "";

  const template = `
    <div class="popup-content">
      <div class="popup-header">${HQ.Properties.i18n.location.maxBound.title}</div>
      <div class="popup-text">${HQ.Properties.i18n.location.maxBound.text}</div>
      ${popupCloseButton}
    </div>  
  `;

  // show popup
  document.body.classList.add("active-popup");

  popupInfo.insertAdjacentHTML("beforeend", template);

  // run translation
  // translator().load(HQ.Properties.lang);

  // close popup after click on button
  closePopup(popupInfo);

  if (!time) return;

  // close popup after a timeout
  closePopupOnTimeout(popupInfo, time);
};

/**
 * Close popup
 */
const closePopup = (popupInfo) => {
  const popupClose = select(".popup-close");

  popupClose?.addEventListener("click", () => {
    document.body.classList.remove("active-popup");
    popupInfo.textContent = "";
  });
};

/**
 * Destroy popup
 */
const popupDestroy = () => {
  const popupInfo = select(".popup-info");
  popupInfo.textContent = "";
  document.body.classList.remove("active-popup");
};

/**
 * Close popup after a timeout
 *
 * @param {HTMLElement} popupInfo
 * @param {number} time
 */
const closePopupOnTimeout = (popupInfo, time) => {
  alert(time);
  setTimeout(() => {
    document.body.classList.remove("active-popup");
    popupInfo.textContent = "";
  }, time);
};

/**
 * Universal popup
 *
 * template => html
 * width => number withoud without unit
 * time => number
 *
 * @param {object} template | width | time
 */
const popupUniversal = ({ template, width, time }) => {
  const popupInfo = select(".popup-info");
  popupInfo.setAttribute("style", width ? `max-width: ${width}px;` : "");
  popupInfo.textContent = "";

  const popupCloseButton =
    '<button class="popup-close"><svg class="icon"><use href="/images/icons/sprite.svg#icon-close"></use></svg></button>';

  const templateHTML = `
    <div class="popup-content">
      ${template}
      ${popupCloseButton}
    </div>  
  `;

  // show popup
  document.body.classList.add("active-popup");

  popupInfo.insertAdjacentHTML("beforeend", templateHTML);

  // close popup after click on button
  closePopup(popupInfo);

  if (!time) return;

  // close popup after a timeout
  closePopupOnTimeout(popupInfo, time);
};

export { popupCreate, popupShow, popupUniversal, popupDestroy };
