// #88 Add event tracking to map
// https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#track

/**
 *
 * @param {string} Captures the name of the tracked event
 * @param {object} Tracks the event properties
 */
const analyticsTracking = (event, properties) => {
  // console.log("eventType", event);
  // console.log("property", { app_id: appId, properties });

  const prop = properties
    ? { app_id: HQ.Properties.appId, ...properties }
    : { app_id: HQ.Properties.appId };

  try {
    rudderanalytics.track(event, prop, () => {
      // console.table({ eventType: event, properties: prop });
    });
  } catch (error) {}
};

export { analyticsTracking };
