/**
 * Add cutom button (back-to-home) to map
 */
const LocationButton = L.Control.extend({
  // button position
  options: {
    position: "topright",
  },

  // method
  onAdd: function (map) {
    const backToHome = L.DomUtil.create("a");
    backToHome.setAttribute("title", HQ.Properties.i18n["slider"].location);
    backToHome.role = "button";
    backToHome.href = "#";
    backToHome.innerHTML = `<svg><use href="/images/icons/sprite.svg#icon-location"></use></svg>`;
    backToHome.className += "leaflet-bar location-button sidebar-location";

    return backToHome;
  },
});

export { LocationButton };
