// --------------------------------------------------
// TrackJS error...
import { TrackJS } from "trackjs";

const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
if (!LOCAL_DOMAINS.includes(window.location.hostname)) {
  TrackJS.install({
    token: "b7b9ae82a8a44a25956a056efdc54e9a",
    version: HQ.Properties.appId,
  });
}

// --------------------------------------------------
// initial map

import map from "./components/initialMap";

import "./components/sidebar/sidebar";
import "./components/sidebar/sliderLayer";
import "./components/iconsLayers";

import {
  getCenterOfMap,
  BackToHomeButton,
} from "./components/map/buttonBackToHome";

import { LocationButton } from "./components/map/buttonLocation";

import {
  informationSettings,
  informationSettingsUpdate,
} from "./components/sidebar/informationSettings";

import autocompleteObj from "./components/sidebar/autocomplete";

import {
  flightsLayer,
  flightsSetOnMap,
  flightsAddAnimationToMarker,
} from "./components/flights";

import { popupCreate, popupDestroy } from "./components/popup";

import {
  leaderboardCreateRow,
  leaderBoardAddFavoritePlayer,
  leaderboardContent,
} from "./components/sidebar/leaderboard";

import highlightsCreateRow from "./components/sidebar/highlights";

import {
  body,
  checkApID,
  cleareActiveSlider,
  convertJSONToArray,
  getBounds,
  getItemLocalStorage,
  hasTouchScreen,
  markFavoritePlayers,
  objectLength,
  select,
  selectAll,
  setItemLocalStorage,
  setPropertyHeight,
  checkIfHeaderExist,
} from "./utils/function";

import showPlacePopup from "./components/map/showPlacePopup";
import showPlayerPopup from "./components/map/showPlayerPopup";
import showHidePlacesOnMap from "./components/map/showHidePlacesOnMap";
import showFirstLayerFlights from "./components/map/showFirstLayerFlights";

import activeInformation from "./components/sidebar/activeInformation";
import activeGroupIsideInformation from "./components/sidebar/activeGroupIsideInformation";

import { LocateControl } from "./components/locateControl";

import { analyticsTracking } from "./components/tracking";
import addTwitsToMap from "./components/sidebar/addTwitsToMap";

// --------------------------------------------------

const root = document.documentElement;

// hide overflow for map when locator address
// if (window.location.pathname === "/locator") {
//   body.classList.add("overflow-hidden");
// }

// --------------------------------------------------
// create div from popup

popupCreate();

// --------------------------------------------------
// add app id to local storage

checkApID();

// ------------------------------------------------------------
// check if device has touch screen
// add to body class

root.style.setProperty(
  "--has-touch-screen",
  hasTouchScreen() ? "flex" : "none"
);

root.style.setProperty(
  "--touch-screen-players",
  hasTouchScreen() ? "0px" : "17px"
);

root.style.setProperty(
  "--header-height",
  checkIfHeaderExist() ? checkIfHeaderExist() : ""
);

// --------------------------------------------------
// action from livewire

window.addEventListener("updateLocator", (event) => {
  HQ.Properties.layerFlights = convertJSONToArray(event.detail.updateFlights);
  HQ.Properties.top5 = event.detail.updateTopFive;
  HQ.Properties.currentRound = event.detail.updateCurrentRound;
  window["updateEntries"] = convertJSONToArray(event.detail.updateEntries);

  console.log("secondCall[top5]", HQ.Properties.top5);
  console.log("updateFlights", HQ.Properties.layerFlights);
  console.log("currentRound", HQ.Properties.currentRound);

  // update leaderboard
  if (objectLength(HQ.Properties.top5) > 0) {
    // update rund number in leaderboard
    const roundNumber = select(".round-number");
    roundNumber.textContent =
      +HQ.Properties.currentRound > 0 ? HQ.Properties.currentRound : "";

    // if (+currentRound > 0) {
    //   if (map.hasLayer(window["icon-start"])) {
    //     map.removeLayer(window["icon-start"]);
    //   }
    // }

    // add new players to leaderboard
    leaderboardContent.insertAdjacentElement(
      "beforeend",
      leaderboardCreateRow("update")
    );

    leaderBoardAddFavoritePlayer();
  }

  // update highlights twits
  highlightsCreateRow();

  // update top5 players set header to language
  // let getLanguage = document.documentElement.lang;
  // translator().load(HQ.Properties.lang);

  // hilight flightsmark the favored players
  markFavoritePlayers("update");

  if (HQ.Properties.layerFlights.length > 0) {
    // check if layer flights is empty window['flights]
    // if (isObjectEmpty(flightsLayer._layers)) {
    // set new flights(group) on map
    flightsSetOnMap(HQ.Properties.layerFlights);

    // removeLocalStorage("informationSettings");
    flightsAddAnimationToMarker(flightsLayer);
    // }

    // update markers position
    // and data in popup
    // flightsUpdateMarkers(HQ.Properties.layerFlights);
  }
});

// --------------------------------------------------
// set first flight on site start

flightsSetOnMap(HQ.Properties.layerFlights);

// console.log("first", HQ.Properties.layerFlights);

// flighs layer to map
flightsLayer.addTo(map);

flightsAddAnimationToMarker(flightsLayer);

// --------------------------------------------------
// remove icon-start when currentRound more than 0

// if (+currentRound > 0) {
//   if (map.hasLayer(window["icon-start"])) {
//     map.removeLayer(window["icon-start"]);
//   }
// }

// --------------------------------------------------
// sidebar action

const sidebar = select(".sidebar");

sidebar.addEventListener("click", (e) => {
  const { target } = e;

  // get current zoom

  const menuItem = target.closest(".menu-item");
  // const layerFlightsExits = map.hasLayer(flights);
  const locationIsActive = body.classList.contains("location-is-active");

  // clouse all popup when click
  // on sidebar button
  map.closePopup();

  const informationIsActive = select(".information-is-active");
  const leaderboardIsActive = select(".leaderboard-is-active");
  const playersIsActive = select(".players-is-active");
  const highlightsIsActive = select(".highlights-is-active");
  // const locationIsActive = select(".location-is-active");
  // --------------------------------------------------
  // leaderboard
  if (menuItem.classList.contains("sidebar-leaderboard")) {
    const leaderboardContent = select(".leaderboard-content");

    if (leaderboardContent.classList.contains("active-slider")) {
      cleareActiveSlider();

      return;
    }

    // acitve flight in information
    activeGroupIsideInformation(flightsLayer);

    if (!map.hasLayer(flightsLayer)) {
      setTimeout(() => {
        body.classList.remove("flags-is-removed");
        // add flights to map
        flightsLayer.addTo(map);
      }, 1000);
    }

    if (!leaderboardIsActive) {
      cleareActiveSlider();
    }

    setTimeout(() => {
      leaderboardContent.classList.add("active-slider");
      body.classList.add("leaderboard-is-active");
    }, 200);

    // tracking
    analyticsTracking("openLeaderboard");

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 45}px`
    );
  }

  // --------------------------------------------------
  // slider-info
  if (menuItem.classList.contains("sidebar-info")) {
    const informationContent = select(".information-content");

    if (informationContent.classList.contains("active-slider")) {
      cleareActiveSlider();

      return;
    }

    if (!informationIsActive) {
      cleareActiveSlider();
    }

    setTimeout(() => {
      informationContent.classList.add("active-slider");
      body.classList.add("information-is-active");
    }, 200);

    // tracking
    analyticsTracking("openInformation");

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 45}px`
    );
  }

  // --------------------------------------------------
  // sidebar-search
  if (menuItem.classList.contains("sidebar-search")) {
    // --------------------------------------------------
    // players
    const playersContentSlider = select(".players-content .slider-content");

    let data = convertJSONToArray(
      objectLength(HQ.Properties.layerFlights) > 0
        ? HQ.Properties.layerFlights
        : window["updateEntries"]
    );

    if (data.length > 0) {
      const autocomplete = `
    <div class="search-autocomplete">
      <div class="auto-search-wrapper max-height">
        <input type="text" id="search-players" autocomplete="off" placeholder="${HQ.Properties.i18n["autocomplete"].placeholder}" />
      </div>
    </div>`;

      playersContentSlider.insertAdjacentHTML("beforeend", autocomplete);

      const auto = new Autocomplete("search-players", autocompleteObj);
    } else {
      playersContentSlider.textContent = "";

      const text = `<div class="p-3 small-font">${HQ.Properties.i18n.autocomplete["no-data-available"]}</div>`;
      playersContentSlider.insertAdjacentHTML("beforeend", text);
    }

    const playersContent = select(".players-content");

    // if click on Player Search change
    // checkbox inside Information - Group
    activeGroupIsideInformation(flightsLayer);

    if (!map.hasLayer(flightsLayer)) {
      setTimeout(() => {
        body.classList.remove("flags-is-removed");
        flightsLayer.addTo(map);
      }, 1000);
    }

    if (playersContent.classList.contains("active-slider")) {
      cleareActiveSlider();
      try {
        auto.destroy();
      } catch (error) {}
      return;
    }

    if (!playersIsActive) {
      cleareActiveSlider();
    }

    setTimeout(() => {
      playersContent.classList.add("active-slider");
      body.classList.add("players-is-active");

      const sliderContent = select(".active-slider > .slider-content");

      setPropertyHeight(
        root,
        "--slider-height",
        `${sliderContent.clientHeight - 110}px`
      );

      const searchPlayers = select("#search-players");
      const autoResultsWrapper = select(".auto-results-wrapper");

      // autocomplete add class and click on input
      autoResultsWrapper?.classList.add("auto-is-active");
      searchPlayers?.click();
    }, 200);

    // tracking
    analyticsTracking("openPlayersearch");

    // add to roo info abuto height sidebar
    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 40}px`
    );
  }

  // --------------------------------------------------
  // highlights
  if (menuItem.classList.contains("sidebar-media")) {
    const highlightsContent = select(".highlights-content");

    if (highlightsContent.classList.contains("active-slider")) {
      cleareActiveSlider();

      return;
    }

    if (!highlightsIsActive) {
      cleareActiveSlider();
    }

    setTimeout(() => {
      highlightsContent.classList.add("active-slider");
      body.classList.add("highlights-is-active");
    }, 200);

    // tracking
    analyticsTracking("openHighlights");

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 45}px`
    );
  }

  // --------------------------------------------------
  // open new window if mode=iframe
  if (menuItem.classList.contains("sidebar-fullscreen-in")) {
    window.open(HQ.Properties.currentUrl);
    analyticsTracking("openFullscreen");
  }
});

// --------------------------------------------------
// close all slider

document.addEventListener("keydown", (e) => {
  const activeSlider = select(".active-slider");

  if (!activeSlider) return;

  if (e.key === "Escape") {
    cleareActiveSlider();
  }
});

// --------------------------------------------------
// search

const getAllDataLayerId = selectAll("[data-layer-id]");
const getAllCheckboxes = selectAll(".switch > input");

document.addEventListener("click", (e) => {
  const target = e.target;

  popupDestroy();

  const rowInformation = target.closest(".information-row");
  const iconStar = target.closest(".icon-star");
  const locatonButton = target.closest(".sidebar-location");

  const clickOnIconStar = target.classList.contains("icon-star");

  if (!target.closest("#slider-wrapper") && !iconStar) {
    cleareActiveSlider();
  }

  if (rowInformation) {
    e.preventDefault();
    const layerID = rowInformation.getAttribute("data-layer-id");

    let layer = window[layerID];

    // console.log("layer", layerID, layer);

    const showAllLayer = layerID === "show-all-layers" ? true : false;

    const input =
      rowInformation.lastElementChild.firstElementChild.firstElementChild;
    input.checked = !input?.checked;

    // update checkbox inside information div
    informationSettingsUpdate(layerID, getAllDataLayerId);

    // --------------------------------------------------
    if (showAllLayer) {
      let firstCheckedIsChecked = getAllCheckboxes[0].checked;
      firstCheckedIsChecked = !firstCheckedIsChecked;

      if (firstCheckedIsChecked) {
        getAllDataLayerId.forEach((element, index) => {
          if (index > 0) {
            map.removeLayer(window[element.dataset.layerId]);
          }
          getAllCheckboxes[index || 0].checked = false;
        });
      } else {
        getAllDataLayerId.forEach((element, index) => {
          if (index > 0) {
            window[element.dataset.layerId].addTo(map);
          }
          getAllCheckboxes[index].checked = true;
        });
      }
    }

    if (layerID === "show-all-layers") return;

    // remove layer if exist
    if (map.hasLayer(layer)) {
      map.removeLayer(layer);
      return;
    }

    layer.addTo(map);
  }

  // --------------------------------------------------
  // click on location
  if (locatonButton) {
    const locatonContent = select(".location-content");

    if (locatonContent.classList.contains("active-slider")) {
      cleareActiveSlider();

      return;
    }

    cleareActiveSlider();

    body.classList.add("location-is-active");

    // start location
    if (hasTouchScreen()) {
      lc.start();
    }

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 50}px`
    );
  }

  // --------------------------------------------------
  // click on icon star

  if (clickOnIconStar) {
    // console.log("clickOnIconStar");
    // update leaderboard
    if (objectLength(HQ.Properties.top5) > 0) {
      leaderboardContent.insertAdjacentElement(
        "beforeend",
        leaderboardCreateRow("update")
      );

      leaderBoardAddFavoritePlayer();
    }
  }

  // --------------------------------------------------
  // click on row in leaderboard

  const flightnumber = target.closest("[data-id-flightnumber]");
  if (flightnumber) {
    const flightNumber = flightnumber.getAttribute("data-id-flightnumber");

    // const clickOnIconStar = target.classList.contains("icon-star");

    if (!flightNumber || clickOnIconStar) return;

    map.eachLayer((layer) => {
      const checkFlights = layer?._icon?.className.includes(
        `flight-${flightNumber}`
      );

      if (layer instanceof L.Marker && checkFlights) {
        // close slider
        cleareActiveSlider();

        map.flyTo(layer.getLatLng(), map.getZoom());
        layer._icon.classList.add("animation");

        layer.openPopup();
      }
    });
  }

  // --------------------------------------------------
  // close slice click on button slider
  if (target.classList.contains("close-slider")) {
    cleareActiveSlider();
  }

  // --------------------------------------------------
  // click on highlights
  if (target.closest(".highlights-row")) {
    const dataTwitsID = target.closest(".highlights-row").dataset.twits;
    const showOnMap = target.closest(".highlights-row").dataset.showOnMap;

    cleareActiveSlider();

    if (showOnMap === "true") {
      activeInformation({ dataTwitsID });
    } else {
      addTwitsToMap({ dataTwitsID });
    }
  }

  // --------------------------------------------------
  // Location accordions
  // how to turn GPS Locatoin on/off

  if (target.closest(".location-content")) {
    if (!target.classList.contains("accordion")) return;

    target.classList.toggle("is-open");

    let content = target.nextElementSibling;

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
    }

    setPropertyHeight(
      root,
      "--sidebar-height",
      `${sidebar.clientHeight + 50}px`
    );
  }
});

// --------------------------------------------------
// set information checkbox
informationSettings(getAllDataLayerId, getAllCheckboxes);

// on zoom end
// map.on("moveend", getCenterOfMap);

// --------------------------------------------------
// set favorite players

markFavoritePlayers();

// --------------------------------------------------
// set highlights information (twits)
highlightsCreateRow();

// --------------------------------------------------
// https://tomik23.github.io/leaflet-examples/#20.maxBound-map-restricts-the-view
// set maxBound map restricts the view

// https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/72

if (typeof HQ.Properties.maxBounds !== "undefined") {
  map.setMaxBounds(getBounds(HQ.Properties.maxBounds));
}

// --------------------------------------------------
// set default translator
// translator().load(HQ.Properties.lang);

// --------------------------------------------------
// set fav player to leaderboard
leaderBoardAddFavoritePlayer();

// --------------------------------------------------
// translator on open popup

// map.on("popupopen", (e) => {
//   // let getLanguage = document.documentElement.lang;
//   translator().load(HQ.Properties.lang);
// });

map.on("moveend", getCenterOfMap);

// --------------------------------------------------
// tracking zoom

let prevZoom = map.getZoom();

map.on("zoomend", function (e) {
  let currZoom = map.getZoom();

  console.log("current zoom", currZoom);

  let diff = prevZoom - currZoom;
  if (diff > 0) {
    analyticsTracking("zoomOut", {
      zoom: prevZoom - 1,
    });
  } else if (diff < 0) {
    analyticsTracking("zoomIn", {
      zoom: prevZoom + 1,
    });
  }
  prevZoom = currZoom;

  // ------------------------------------------------
  // showing and hiding icons on the map
  // depending on the zoom level

  showHidePlacesOnMap(currZoom);
});

// --------------------------------------------------
// location button

map.addControl(new LocationButton());

// --------------------------------------------------
// center map click on button

map.addControl(new BackToHomeButton());

// --------------------------------------------------
// only one time show this information
// fist wisit site ask for permission to use location

if (hasTouchScreen()) {
  var lc = new LocateControl({
    position: "topright",
    drawCircle: true,
    showPopup: false,
    showCompass: true,
    cacheLocation: true,
    maxBounds:
      typeof HQ.Properties.maxBounds !== "undefined" ? getBounds() : "",
    keepCurrentZoomLevel: true,
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
     * I checked google maps mobile and desktop
     * On desktop:
     * maximumAge is set to 3E5 = 997 decimal
     * timeout 10 seconds and enagbleHighAccuracy is set to true
     *
     * On mobile:
     * timeout: 1E4 // 10000 - 10 seconds
     * maximumAge: 2E3 // 20000 - 20 seconds
     *
     * We need to reduce the maximumAge because we need the most accurate data.
     *
     */
    locateOptions: {
      enableHighAccuracy: true,
      timeout: 10 * 1000, // 10 seconds
      maximumAge: 3000,
    },
  }).addTo(map);

  const firstVisitCheck = getItemLocalStorage("HQfirstVisit");
  if (!firstVisitCheck) {
    setItemLocalStorage("HQfirstVisit", true);
    lc.start();
  } else {
    lc.start();
  }

  // --------------------------------------------------
  // focus
  // window.addEventListener("focus", () => {
  //   console.log("has focus");
  //   lc.start();
  //   console.log("lc 2");
  // });
}

// --------------------------------------------------
// show first layer icon-flights

showFirstLayerFlights();

// --------------------------------------------------
// open popup when url have ?place=id id
// is a number from DB

showPlacePopup({ currentZoom: prevZoom });

// --------------------------------------------------
// Show player when /locator?player=21

showPlayerPopup(flightsLayer);

// --------------------------------------------------
// show lat + lng in console log when
// click on map for testing purposes only

// map.on("click", function (e) {
//   console.log(e.latlng.lat + ", " + e.latlng.lng);
// });
