/**
 * Show first layer icon-flights
 * open popup
 */

import { hasTouchScreen } from "../../utils/function";

const showFirstLayerFlights = () => {
  if (HQ.Properties.mode !== "iframe" && !hasTouchScreen()) {
    let checkLayer = false;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          layer.options.icon.options.className.search("icon-flights-start") !==
            -1 &&
          !checkLayer
        ) {
          layer.openPopup();
          checkLayer = true;
        }
      }
    });
  }
};

export default showFirstLayerFlights;
