import customIcon from "../customIcon";
import { analyticsTracking } from "../tracking";

const addTwitsToMap = ({ dataTwitsID }) => {
  HQ.Properties.highlights.map((obj) => {
    if (obj.id === dataTwitsID) {
      // console.log(obj);
      const {
        id,
        icon,
        color,
        color_front,
        color_outline,
        latitude,
        html,
        name_tracking,
        longitude,
      } = obj;

      if (window[`marker-${dataTwitsID}`] === undefined) {
        window[`marker-${dataTwitsID}`] = L.marker([latitude, longitude], {
          icon: customIcon({
            id,
            icon,
            color,
            color_front,
            color_outline,
          }),
        })
          .addTo(map)
          .bindPopup(html)
          .on("click", function (e) {
            const { lat, lng } = e.latlng;
            analyticsTracking("click_place", {
              name: name_tracking,
              icon,
              icon_shortcode: null,
              description: "click",
              lat,
              lng,
            });
          })
          .on("popupopen", function (e) {
            setTimeout(() => {
              analyticsTracking("click_highlight", {
                name: name_tracking,
                icon,
                icon_shortcode: null,
                description: "",
                lat: latitude,
                lng: longitude,
              });
            }, 1000);
          })
          .openPopup();
      } else {
        let checkPlayes = false;
        map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            if (
              layer.options.icon.options.className.search(
                `place-${dataTwitsID}`
              ) !== -1 &&
              !checkPlayes
            ) {
              layer.openPopup();
              checkPlayes = true;
            }
          }
        });
      }
    }
  });
};

export default addTwitsToMap;
