import map from "../initialMap";
import { defaults } from "../../helpers/defaults";
import { setItemLocalStorage, select } from "../../utils/function";

import { zoomOnMobileOrDesktop } from "./zoomMobileOrDesktop";

import { popupDestroy } from "../popup";

let { lat, lng } = defaults.coordinates;

/**
 * Add cutom button (back-to-home) to map
 */
const BackToHomeButton = L.Control.extend({
  // button position
  options: {
    position: "topright",
  },

  // method
  onAdd: function (map) {
    const backToHome = L.DomUtil.create("a");
    backToHome.setAttribute("title", HQ.Properties.i18n["back-to-home"].title);
    backToHome.role = "button";
    backToHome.href = "#";
    backToHome.innerHTML = `<svg><use href="/images/icons/sprite.svg#icon-center"></use></svg>`;
    backToHome.className += "leaflet-bar back-to-home d-none";

    return backToHome;
  },
});

// --------------------------------------------------

/**
 * Get center of the map
 */
const getCenterOfMap = () => {
  const backToHomeBtn = select(".back-to-home");
  const locationIsActive = select(".location-is-active");

  // show button
  backToHomeBtn.classList.remove("d-none");

  locationIsActive?.classList.remove("location-is-active");

  // destroy popup
  popupDestroy();

  setItemLocalStorage("HQlocationWatching", false);
  // clicking on home-back set default view and zoom
  backToHomeBtn.addEventListener("click", backToCenter);
};

const backToCenter = () => {
  const backToHomeBtn = select(".back-to-home");

  console.log("back to home");

  // if touch screen false

  // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/273
  // https://leafletjs.com/reference.html#map-setview
  // https://leafletjs.com/reference.html#map-setview
  map.setView([lat, lng], zoomOnMobileOrDesktop);
  // map.flyTo([lat, lng], zoomOnMobileOrDesktop);

  map.on("moveend", () => {
    const { lat: latCenter, lng: lngCenter } = map.getCenter();

    const latC = latCenter.toFixed(3) * 1;
    const lngC = lngCenter.toFixed(3) * 1;

    const defaultCoordinate = [
      Number(lat).toFixed(3) * 1,
      Number(lng).toFixed(3) * 1,
    ];

    const centerCoordinate = [latC, lngC];

    const compareToArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

    if (compareToArrays(centerCoordinate, defaultCoordinate)) {
      backToHomeBtn.classList.add("d-none");
    }
  });

  backToHomeBtn.removeEventListener("click", backToCenter);
};

export { getCenterOfMap, BackToHomeButton };
