import { iconsType } from "../utils/function";
import { defaults } from "../helpers/defaults";

const customIcon = ({
  id,
  icon,
  content = "",
  iconConfig = defaults.iconSize,
  matchnumber_str,
  color,
  color_front,
  color_outline,
  players,
}) => {
  const { w, h } = iconConfig;

  // console.log(players);

  const playersClass = players?.map((player) => `player-${player} `);

  const playerWidthId = playersClass ? playersClass : "";

  // this icon use in auto complete when click on player
  const flighIcon =
    icon === "icon-flights"
      ? ` flight-${matchnumber_str} ${playerWidthId.join("")}`
      : "";

  const idPlace = id ? ` place-${id}` : "";

  return L.divIcon({
    html: iconsType({
      icon,
      content,
      color,
      color_front,
      color_outline,
      players,
    }),
    className: `icon ${icon} ${flighIcon}${idPlace}`,
    iconSize: [w, h],
    iconAnchor: [icon === "icon-hole" ? 17 : 22, 20],
    popupAnchor: [-4, -20],
  });
};

export default customIcon;
