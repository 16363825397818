import map from "./initialMap";
import customIcon from "./customIcon";
import {
  convertJSONToArray,
  select,
  showInformationAboutPlacesRow,
} from "../utils/function";
import { analyticsTracking } from "./tracking";

const iconSizeForHole = { w: 24, h: 24 };
const zoomLevelDefault = Math.floor(map.getZoom());
const checkIfTypeSiteIsBackend =
  HQ.Properties.typeSite === "backend" ? true : false;
// --------------------------------------------------
// grou by name

console.log("places", HQ.Properties.places);

const placesObj = convertJSONToArray(HQ.Properties.places).reduce(
  (acc, curr) => {
    const { id, icon_shortcode, name, name_tracking, icon, parent_id } = curr;
    if (!acc.get(parent_id)) {
      acc.set(id, {
        id,
        icon,
        name,
        name_tracking,
        icon_shortcode,
        children: [curr],
      });
    } else acc.get(parent_id)?.children?.push(curr);
    return acc;
  },
  new Map()
);

window["resultsPlaces"] = [...placesObj.values()];

// console.log("results", window["resultsPlaces"]);

window["resultsPlaces"].map(({ id, icon, children }) => {
  let layerName = icon?.replace(/icon-/g, "");

  const layerNameWithId = `${layerName}-${id}`;

  window[layerNameWithId] = L.featureGroup().addTo(map);

  if (checkIfTypeSiteIsBackend) {
    window[layerNameWithId].addTo(map);
  }
  children.map(
    ({
      id,
      name,
      name_tracking,
      hole,
      icon,
      html,
      description,
      icon_shortcode,
      latitude,
      longitude,
      area,
      color,
      color_front,
      zoom_levels,
      color_outline,
    }) => {
      let popupContent;
      let options;

      // console.log("zl", name, zoom_levels);
      const checkIfZoomLevelIsInArray = zoom_levels.includes(zoomLevelDefault);
      // console.log(checkIfZoomLevelIsInArray);

      if (icon === "icon-hole") {
        let { avg_score, metres, no, par, yards } = hole;

        avg_score = avg_score
          ? `<div class="hole-info-avg">Avg. score: ${avg_score}</div>`
          : "";

        popupContent = `
          <div class="hole-info">
            <div class="hole-info-par"><strong>${HQ.Properties.i18n["popup-content"]["hole"]} ${no}</strong> PAR ${par}</div>
            <div class="hole-info-units">${metres}m/${yards}y</div>
            ${avg_score}
          </div>`;
      } else {
        if (html) {
          popupContent = html;
          options = { minWidth: 240 };
        } else if (description) {
          options = { minWidth: 240 };
          popupContent = `${name}<br>${description}`;
        } else {
          options = "";
          popupContent = name;
        }
      }

      if (latitude) {
        const contentDescription =
          icon === "icon-hole"
            ? {
                content: icon_shortcode,
                iconConfig: iconSizeForHole,
              }
            : { content: icon_shortcode };

        window[`marker-${id}`] = L.marker([latitude, longitude], {
          icon: customIcon({
            id,
            icon,
            ...contentDescription,
            color,
            color_front,
            color_outline,
          }),
        })
          .on("click", function (e) {
            const { lat, lng } = e.latlng;
            analyticsTracking("click_place", {
              name: name_tracking,
              icon,
              icon_shortcode,
              description,
              lat,
              lng,
            });
          })
          .bindPopup(popupContent, options);

        // check if zoom_levels contains
        // the current zoom level on map
        // if true add all icon to the map
        if (checkIfZoomLevelIsInArray) {
          window[`marker-${id}`].addTo(window[layerNameWithId]);
        }

        if (checkIfTypeSiteIsBackend) {
          window[`marker-${id}`].addTo(window[layerNameWithId]);
        }
      }

      if (area) {
        const areaJSON = JSON.parse(area);
        window[`area-${id}`] = geoJSONLayer({
          id,
          name: name_tracking,
          icon,
          icon_shortcode,
          description,
          area: areaJSON,
          popupContent,
        });
        // check if zoom_levels contains
        // the current zoom level on map
        // if true add all polygon to the map
        if (checkIfZoomLevelIsInArray) {
          window[`area-${id}`].addTo(window[layerNameWithId]);
        }

        if (checkIfTypeSiteIsBackend) {
          window[`area-${id}`].addTo(window[layerNameWithId]);
        }
      }
    }
  );
});

function geoJSONLayer({
  id,
  name_tracking,
  icon,
  icon_shortcode,
  description,
  area,
  popupContent,

  color = "#1E88E5",
}) {
  const dashArray = icon === "icon-hole" && area ? 3 : 0;

  const geoJsonLayers = L.geoJSON(area, {
    style: function (feature) {
      color =
        icon === "icon-hole" && area
          ? feature.properties.color || "#ffffff"
          : feature.properties.color || color;
      return {
        color,
        weight: 2,
        dashArray,
        fillOpacity: feature.properties.fillOpacity || 1,
        className: id ? `place-${id}` : "",
      };
    },
    onEachFeature: function (feature, layer) {
      layer.on("click", function (e) {
        const { lat, lng } = e.latlng;
        analyticsTracking("click_place", {
          name: name_tracking,
          icon,
          icon_shortcode,
          description,
          lat,
          lng,
        });
      });
    },
  }).bindPopup(popupContent);

  if (checkIfTypeSiteIsBackend) {
    geoJsonLayers.addTo(map);
  }

  return geoJsonLayers;
}

// --------------------------------------------------
// add all icons and information to information-conten

if (HQ.Properties.typeSite === "locator") {
  const informationContent = select(".information-content .slider-content");
  informationContent.insertAdjacentHTML(
    "beforeend",
    showInformationAboutPlacesRow(window["resultsPlaces"])
  );
}
