import { createElement, select } from "../../utils/function";

// --------------------------------------------------
// sidebar information

const leaderboardRoundInformation =
  typeof HQ.Properties.currentRound !== "undefined"
    ? ` - <span>${
        HQ.Properties.i18n["slider"].round
      }</span><span class="round-number">${
        +HQ.Properties.currentRound > 0 ? HQ.Properties.currentRound : ""
      }</span>`
    : "";

const sliderElements = [
  {
    i18n: "information",
    title: "Information",
    className: "information-content",
  },
  {
    i18n: "player-search",
    title: "Players search",
    className: "players-content",
  },
  {
    i18n: "leaderboard",
    title: "Leaderboard",
    className: "leaderboard-content",
  },
  {
    i18n: "highlights",
    title: "Highlights",
    className: "highlights-content",
  },
  {
    i18n: "location",
    title: "Location",
    className: "location-content",
  },
];

function getImageFromTop() {
  const image = document.body.dataset.logo;

  const createImage = createElement("img");
  createImage.src = image;
  createImage.setAttribute("width", "auto");
  createImage.setAttribute("height", "100%");
  createImage.alt = "logo";

  return createImage;
}

const mapContainer = select("#slider-wrapper");

sliderElements.forEach((sliderElement, index) => {
  const { i18n, title, className } = sliderElement;

  const slider = createElement("div");
  const content = slider.cloneNode(false);
  slider.classList.add(className);
  slider.classList.add("slider");

  const sliderTitle = createElement("div");
  sliderTitle.classList.add("slider-title");
  sliderTitle.classList.add(`${className}-title`);
  sliderTitle.append(getImageFromTop());

  const titleElement = `<span>${HQ.Properties.i18n.slider[i18n]}</span>`;
  const titleContent =
    index === 2
      ? `${titleElement}${leaderboardRoundInformation}`
      : titleElement;
  sliderTitle.insertAdjacentHTML("beforeend", titleContent);

  const buttonClose = createElement("button");
  buttonClose.classList.add("close-slider");
  buttonClose.setAttribute("aria-label", title);

  buttonClose.insertAdjacentHTML(
    "beforeend",
    '<svg class="icon"><use href="/images/icons/sprite.svg#icon-close"></use></svg>'
  );

  content.classList.add("slider-content");

  slider.appendChild(sliderTitle);
  slider.appendChild(buttonClose);
  slider.appendChild(content);

  mapContainer.insertAdjacentElement("afterbegin", slider);
});
