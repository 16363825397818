import { checkIfVarExist, select, selectAll } from "../../utils/function";
import map from "../initialMap";
import showHidePlacesOnMap from "./showHidePlacesOnMap";
import addTwitsToMap from "./../sidebar/addTwitsToMap";
import { analyticsTracking } from "../tracking";

/**
 * Show player popup on id
 *
 * open popup on locator site
 * all places have place-id - id is a number from DB
 */
const showPlacePopup = ({
  placeID,
  idInformation,
  dataLayerId,
  currentZoom,
} = {}) => {
  if (!placeID) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // console.log("place", urlParams.has("place"));
    // console.log("highlight", urlParams.has("highlight"));

    placeID = urlParams.get("place") || urlParams.get("highlight");
  }

  // if (idInformation) {
  //   const mapHasTwits =
  //     idInformation === "flights"
  //       ? map.hasLayer(window["flights"])
  //       : map.hasLayer(window[`marker-${placeID}`]);

  //   if (!mapHasTwits) {
  //     const informationCheckbox = select(`[data-layer-id="${dataLayerId}"]`);
  //     informationCheckbox?.click();
  //   }
  // }

  // check twits in Highlights
  const dataTwits = select(`[data-twits="${placeID}"]`);
  if (dataTwits?.dataset.showOnMap === "false") {
    addTwitsToMap({ dataTwitsID: placeID });
  }

  if (placeID) {
    const zoom = Math.floor(currentZoom);
    // console.log("test", HQ.Properties.places);

    // setView
    HQ.Properties.places.map(({ id, latitude, longitude, zoom_levels }) => {
      if (Number(id) === Number(placeID)) {
        const firstZoom = zoom_levels.shift();
        if (zoom < firstZoom) {
          if (latitude) {
            // map.setZoom(firstZoom);
            showHidePlacesOnMap(firstZoom);
            map.setView([latitude, longitude], firstZoom, { animation: true });
          }
        }
      }
    });

    // click on specific checkbox in information slider
    const dataChildren = selectAll(".information-row");
    [...dataChildren].map((child) => {
      // console.log(
      //   "checkIfVarExist",
      //   checkIfVarExist(window[`marker-${placeID}`])
      // );

      if (!checkIfVarExist(window[`marker-${placeID}`])) return;

      const childrenArray = child.dataset.children.split(",");
      const check = childrenArray.includes(placeID);

      if (check) {
        const checkPlaceOnID = map.hasLayer(window[`marker-${placeID}`]);
        const dataLayerId = child.dataset.layerId;

        if (!checkPlaceOnID) {
          const informationCheckbox = select(
            `[data-layer-id="${dataLayerId}"]`
          );
          informationCheckbox?.click();
        }
      }
    });

    const findPlaceByID = HQ.Properties.places
      .filter((c) => c.id == placeID)
      .map((el) => el)
      .pop();

    const { icon, name_tracking, latitude, longitude } = findPlaceByID;

    let checkPlayes = false;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (
          layer.options.icon.options.className.search(`place-${placeID}`) !==
            -1 &&
          !checkPlayes
        ) {
          layer
            .on("popupopen", function (e) {
              setTimeout(() => {
                analyticsTracking("click_highlight", {
                  name: name_tracking,
                  icon,
                  icon_shortcode: null,
                  description: "",
                  lat: latitude,
                  lng: longitude,
                });
              }, 1000);
            })
            .openPopup();
          checkPlayes = true;
        }
      }

      if (layer._path) {
        let checkPolygon = false;
        if (
          layer._path.className.baseVal.search(`place-${placeID}`) !== -1 &&
          !checkPolygon
        ) {
          window[`area-${placeID}`].openPopup();
          checkPolygon = false;
        }
      }
    });
  }
};

export default showPlacePopup;
