// translate text on site
import map from "./initialMap";
import showPlayersRow from "./sidebar/showPlayersRow";
import customIcon from "./customIcon";
import { convertJSONToArray, cleareActiveSlider } from "../utils/function";

import { analyticsTracking } from "./tracking";

import "./markerSlideTo";

// --------------------------------------------------
// flights layer
window["flights"] = L.featureGroup().addTo(map);

// L.marker([53.32354878961216, 10.234521942911671]).addTo(map);

/**
 * Set flights on map
 * red marker
 *
 * @param {object} flights
 */
const flightsSetOnMap = (flights) => {
  if (!HQ.Properties.showGroups) return;

  convertJSONToArray(flights).map((flight, index) => {
    const {
      matchnumber,
      lat,
      lng,
      matchnumber_str,
      color,
      currenthole,
      status,
    } = flight;

    // console.log(matchnumber_str, status);

    if (window[matchnumber_str] !== undefined) {
      // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/276
      // remove layer from map when status marker is FINISHED
      if (status === "FINISHED") {
        setTimeout(() => {
          map.removeLayer(window[matchnumber_str]);
        }, 2500);
        return;
      }

      // check if window[matchnumber_str] undefined
      if (window["flights"].hasLayer(window[matchnumber_str])) {
        // console.log("yes", matchnumber_str);
        if (lat) {
          flightsUpdate(flight, flights);
        }
        // console.log(matchnumber_str);
        return;
      } else {
        // console.log("no");
      }
    }

    if (!lat) return;

    let holeCurent = [];
    Object.entries(flight.players).map((player) => {
      const [key, value] = player;
      holeCurent.push(value.id);
    });

    // console.log(color);

    const content = showPlayersRow(flight);

    const popup = L.popup().setContent(content);
    window[matchnumber_str] = L.marker([lat, lng], {
      layerID: matchnumber,
      // https://leafletjs.com/reference.html#marker-zindexoffset
      // zIndexOffset: 500,
      icon: customIcon({
        icon: "icon-flights",
        content: matchnumber,
        color,
        matchnumber_str,
        players: holeCurent,
        curentHole: currenthole,
      }),
    });

    window[matchnumber_str]
      .bindPopup(popup, {
        className: "group-popup",
        autoPanPadding: [20, 160],
      })
      .addTo(window["flights"])
      .on("click", (e) => {
        map.panTo(e.latlng);

        analyticsTracking("click_place", {
          name: "flights",
          icon: "icon-flights",
          icon_shortcode: "",
          description: "",
          lat: Number(lat),
          lng: Number(lng),
        });
        cleareActiveSlider();
      })
      .on("popupopen", function (e) {
        setTimeout(() => {
          analyticsTracking("click_place", {
            name: "flights",
            icon: "icon-flights",
            icon_shortcode: "",
            description: "",
            lat: Number(lat),
            lng: Number(lng),
          });
        }, 1000);
      });
  });
};

const flightsLayer = window["flights"];

/**
 * Move marker on new position
 *
 * @param {object} flightsLayer
 */
const flightsUpdate = (flight, flights) => {
  const { lat, lng, matchnumber_str } = flight;

  if (lat === undefined) {
    // window[matchnumber_str].remove();
    window["flights"].removeLayer(window[matchnumber_str]);
    console.log("remove layer", matchnumber_str);

    return;
  }

  const content = showPlayersRow(flight);
  window[matchnumber_str].setPopupContent(content);

  if (typeof lat !== "undefined" || typeof lng !== "undefined") {
    window[matchnumber_str].slideTo([lat, lng], {
      duration: 2000,
    });
  }
};

/**
 * Added class animation to marker when click on marker
 * or when search flights when use autocomplete
 *
 * @param {object} flightsLayer
 */
const flightsAddAnimationToMarker = (flightsLayer) => {
  const className = "animation";

  return flightsLayer.eachLayer((layer) => {
    layer.on("click", ({ target }) => {
      target?._icon?.classList.add(className);
    });

    // remove class when popup is closed
    layer.on("popupclose", ({ target }) => {
      target?._icon?.classList.remove(className);
    });
  });
};

export { flightsAddAnimationToMarker, flightsLayer, flightsSetOnMap };
