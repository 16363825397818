import { defaults } from "../helpers/defaults";
import { select } from "../utils/function";
import { zoomOnMobileOrDesktop } from "./map/zoomMobileOrDesktop";
// https://github.com/GIAPspzoo/L.TileLayer.Canvas
import "./tileLayerCanvas.js";
// import "./tileLayerNoGap.js";

let { lat, lng, maxZoom, minZoom } = HQ.Properties.mapCoordinates;

// --------------------------------------------------
// generate map

const map = L.map("map", {
  // renderer: L.svg(),
  // renderer: L.canvas(),
  center: [lat, lng],
  zoomControl: false, // zoom control off
  zoomDelta: 0.25,
  zoomSnap: 0.1,
  maxZoom,
  minZoom,
  zoom: zoomOnMobileOrDesktop,
  tap: false,
});
// .setView([mapCoordinates.lat, mapCoordinates.lng], mapCoordinates.zoom);

// --------------------------------------------------
// move zoom to top right

L.control.zoom({ position: "topright" }).addTo(map);

// --------------------------------------------------
// custom layer
// `https://tile.openstreetmap.org/{z}/{x}/{y}.png?key=${defaults.baseMapKey}`,

if (defaults.baseMapUrl) {
  L.tileLayer(`${defaults.baseMapUrl}?key=${defaults.baseMapKey}`, {
    attribution: defaults.baseMapAttribution,
    maxZoom,
    minZoom,
  }).addTo(map);
}

// --------------------------------------------------
// Custom event layer
// plugion for canvas layer
// github.com/GIAPspzoo/L.TileLayer.Canvass

if (defaults.customLink) {
  window["defaults-map"] = L.tileLayer
    .canvas(`${defaults.customLink}`, {
      doubleSize: true,
      className: "golf-layer",
      attribution: defaults.customAttribution,
      crossOrigin: true,
      minZoom,
      maxZoom,
    })
    .addTo(map);
}

const zoomIn = select(".leaflet-control-zoom-in");
zoomIn.setAttribute("title", HQ.Properties.i18n["zoom-in"].title);

const zoomOut = select(".leaflet-control-zoom-out");
zoomOut.setAttribute("title", HQ.Properties.i18n["zoom-out"].title);

window["map"] = map;

// https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/136
// fix for safari
// (function () {
//   var originalInitTile = L.GridLayer.prototype._initTile;
//   L.GridLayer.include({
//     _initTile: function (tile) {
//       originalInitTile.call(this, tile);

//       var tileSize = this.getTileSize();

//       tile.style.width = tileSize.x + 1 + "px";
//       tile.style.height = tileSize.y + 1 + "px";
//     },
//   });
// })();

export default map;
