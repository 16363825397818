import {
  select,
  createElement,
  checkIfVarExist,
  convertJSONToArray,
} from "../../utils/function";

const highlightsCreateRow = () => {
  // console.log("highlightsCreateRow");
  const highlightsContent = select(".highlights-content .slider-content");

  highlightsContent.textContent = "";

  const wrapper = createElement("div");
  wrapper.classList.add("highlights-wrapper");
  wrapper.classList.add("container");

  // const highlights = convertJSONToArray(HQ.Properties.highlights);
  const highlights = HQ.Properties.highlights;
  console.log("highlights array", highlights);

  if (!checkIfVarExist(HQ.Properties.highlights)) return;

  const twitsRow = highlights
    ?.map((twitsRow) => {
      // console.log(twitsRow);
      const {
        id,
        preview_image_url,
        text,
        round,
        hole_id,
        player,
        show_on_map,
      } = twitsRow;

      const holeInfo = hole_id ? `, Hole: <strong>${hole_id}</strong>` : "";
      const roundInfo = round ? `Round: <strong>${round}</strong>` : "";
      const playerFullName = player
        ? `, <strong>${player.fullname}</strong>`
        : "";

      return `
      <div class="row highlights-row py-2" data-show-on-map="${show_on_map}" data-twits="${id}">
        <div class="col-2 highlights-logo">
          <img src="${preview_image_url}" class="img-fluid">
        </div>
        <div class="col-10">
          <div class="highlights-text">${text}</div>
          <div>
            <span class="highlights-round">${roundInfo}${holeInfo}</span>
            <span class="highlights-player">${playerFullName}</span>
          </div>
        </div>
      </div>
    `;
    })
    .join("");

  wrapper.insertAdjacentHTML("beforeend", twitsRow);
  highlightsContent.insertAdjacentElement("beforeend", wrapper);
};

export default highlightsCreateRow;
