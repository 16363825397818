import {
  convertJSONToArray,
  select,
  getItemLocalStorage,
  selectAll,
  setItemLocalStorage,
  markFavoritePlayers,
  createElement,
  checkIfVarExist,
  objectLength,
} from "../../utils/function";

const checkCut = typeof HQ.Properties.cut !== "undefined" ? true : false;

const leaderboardContent = select(".leaderboard-content .slider-content");
const storageName = "HQfavoritePlayers";

// check if currentRound var exist
let checkCurrentRound =
  typeof HQ.Properties.currentRound !== "undefined" ? true : false;

const firstRoundHeader = [
  {
    classNames: "col-1 text-center top-five-position",
    text: "pos",
    i18n: "pos",
  },
  {
    classNames: "col-5 top-five-fullname",
    text: "name",
    i18n: "name",
  },
  {
    classNames: "col-2 text-truncate text-center top-five-country",
    text: "",
  },
  {
    classNames: "col-1 text-center top-five-total",
    text: "total",
    i18n: "total",
  },
  {
    classNames: "col-2 text-center top-five-thru",
    text: "thru",
    i18n: "thru",
  },
  {
    classNames: "col-1 text-center top-fav",
    text: "fav",
    i18n: "favorite",
  },
];

const nextRoundsHeader = [
  {
    classNames: "col-1 text-center top-five-position",
    text: "pos",
    i18n: "pos",
  },
  {
    classNames: "col-5 top-five-fullname",
    text: "name",
    i18n: "name",
  },
  {
    classNames: "col-1 text-truncate text-center top-five-country",
    text: "",
    i18n: "",
  },
  {
    classNames: "col-1 text-center top-five-today",
    text: "today",
    i18n: "today",
  },
  {
    classNames: "col-2 text-center top-five-total",
    text: "total",
    i18n: "total",
  },
  {
    classNames: "col-1 text-center top-five-thru",
    text: "thru",
    i18n: "thru",
  },
  {
    classNames: "col-1 text-center top-fav",
    text: "fav",
    i18n: "favorite",
  },
];

// console.log("HQ.Properties.top5", HQ.Properties.top5);
// console.log("HQ.Properties.layerFlights", HQ.Properties.layerFlights);

const leaderboardCreateRow = (type) => {
  let checkCurrentRound =
    typeof HQ.Properties.currentRound !== "undefined" ? true : false;

  const templateHead =
    checkCurrentRound && +HQ.Properties.currentRound > 1
      ? createHeader(nextRoundsHeader, "nextRounds")
      : createHeader(firstRoundHeader, "firstRound");

  // currentRound = +currentRound;
  // console.log("checkCurrentRound", checkCurrentRound);

  const top5 = HQ.Properties.top5;
  if (type === "update") {
    leaderboardContent.textContent = "";
  }

  let only5 = convertJSONToArray(top5);

  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  // get only fav players from only5 array
  // add fav: true element
  const favPlayers = only5
    .filter((player) => getLocalStorage?.includes(player.id))
    .map((item) => {
      return { ...item, fav: true };
    });

  // marging favPlayers array with only5 array
  only5 = getLocalStorage ? [...favPlayers, ...only5] : only5;

  const wrapper = createElement("div");
  wrapper.classList.add("top-five-wrapper");
  wrapper.classList.add("container");

  // add header row to wrapper
  wrapper.insertAdjacentHTML("afterbegin", templateHead);

  only5.forEach((element, index) => {
    let {
      id,
      fav,
      proam,
      position,
      country,
      fullname,
      firstname,
      lastname,
      teetime,
      holesplayed,
      matchnumber,
      totaltopar,
      totaltopar_str,
      strokes,
      rounds,
    } = element;

    // console.log("element", element);

    if (proam) {
      proam = proam === "am" ? "(A)" : "";
    } else {
      proam = "";
    }

    // get total html elements
    const totalColumn = totaltoparInformation(totaltopar, totaltopar_str);

    // find ["NSY" - "TEE" - "PLAYING" - "FINISHED"],  in array HQ.Properties.layerFlights
    let { status, matchnumber_str, score, currenthole, startingtee } =
      findLeaderboardInformation(id);

    status = typeof status !== "undefined" ? status : "";

    // const todayColumn = rounds[currentRound].score;
    let totaltoparData = totaltoparInformation(totaltopar, totaltopar_str);
    let todayColumn = checkCurrentRound
      ? +HQ.Properties.currentRound > 1
        ? rounds[HQ.Properties.currentRound]?.score
        : totaltoparData !== null
        ? totaltoparData
        : ""
      : "";

    const thrucolumnrounds = thruColumnRounds(rounds);

    const thruColumn =
      typeof thrucolumnrounds !== "undefined" ? thruColumnRounds(rounds) : "";

    // add color for rows
    const colorRow = index % 2 === 0 ? "bg-light" : "bg-white";

    // diffrent border-color for rows
    let favPlayers = fav ? 'data-fav="true"' : "";

    let flightnumber = "";
    let iconPlayer = "";
    if (checkCurrentRound && (status === "PLAYING" || status === "TEE")) {
      flightnumber = `data-id-flightnumber="${matchnumber_str}"`;
      iconPlayer = `<span class="player-icon"><svg fill="#3e3e3e"><use href="/images/icons/sprite.svg#icon-flights"></use></svg></span>`;
    }

    // check previus position === current position
    // dont show number
    let previusPosition = only5[index - 1]?.position;

    // show only one latter - firstname
    let playerName = `${firstname.slice(
      0,
      1
    )}. ${lastname} ${proam} ${iconPlayer}`;
    // console.log(namePlayer);

    const pos = position === previusPosition ? "" : position || "-";

    // show cut row - red

    // console.log("cut", HQ.Properties.cut);
    // console.log("totaltopar", totaltopar);
    // console.log("only5", only5[index - 1]?.totaltopar);

    let $showCutRow = false;
    let $cutNumber = "";
    let cutRow = "";
    if (checkCut) {
      if (
        (HQ.Properties.cut || HQ.Properties.cut === 0) &&
        totaltopar > HQ.Properties.cut &&
        only5[index - 1]?.totaltopar === HQ.Properties.cut
      ) {
        $showCutRow = true;
        if (HQ.Properties.cut < 0) {
          $cutNumber = HQ.Properties.cut;
        } else if (HQ.Properties.cut === 0) {
          $cutNumber = "PAR";
        } else if (HQ.Properties.cut > 0) {
          $cutNumber = "+" + HQ.Properties.cut;
        }
      }

      cutRow = `<div class="projected-cut py-1 text-white">PROJECTED CUT ${$cutNumber}</div>`;
    }

    // temaplate for first round
    const firstRound = `
      <div ${favPlayers} ${flightnumber} data-id=${id} class="d-flex align-items-center row row-item py-1 ${colorRow}">
        <div class="p-2 col-1 text-center top-five-position">${pos}</div>
        <div class="p-2 col-5 top-five-fullname">${playerName}</div>
        <div class="p-2 col-2 text-center top-five-country">
          <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
        </div>
        <div class="p-2 col-1 text-center small-text top-five-total">${
          todayColumn === undefined ? "" : todayColumn
        }</div>
        <div class="p-2 col-2 text-center small-text top-five-thru">${thruColumn}</div>
        <div class="p-1 col-1 text-center top-fav">
          <div class="icon-star">
            <svg>
              <use href="/images/icons/sprite.svg#icon-star"></use>
            </svg>
          </div>
        </div>
      </div>
    `;

    // template for next rounds [2,3...]
    const nextRounds = `
       ${$showCutRow && !fav ? cutRow : ""}
      <div ${favPlayers} ${flightnumber} data-id=${id} class="d-flex align-items-center row row-item py-1 ${colorRow}">
        <div class="p-1 col-1 text-center top-five-position">${pos}</div>
        <div class="p-1 col-5 top-five-fullname">${playerName}</div>
        <div class="p-1 col-1 text-center top-five-country">
          <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
        </div>
        <div class="p-1 col-1 text-center small-text top-five-today">${
          todayColumn === undefined ? "" : todayColumn
        }</div>
        ${
          checkCurrentRound && +HQ.Properties.currentRound > 1
            ? `<div class="p-1 col-2 text-center top-five-total">${
                totalColumn ? totalColumn : ""
              }</div>`
            : ""
        }
        <div class="p-1 col-1 text-center small-text top-five-thru">${thruColumn}</div>
        <div class="p-1 col-1 text-center top-fav">
          <div class="icon-star">
            <svg>
              <use href="/images/icons/sprite.svg#icon-star"></use>
            </svg>
          </div>
        </div>
      </div>
    `;

    // console.log(
    //   `${
    //     $showCutRow
    //       ? `<div class="projected-cut py-1 text-white" style="background-color: #ff0000">PROJECTED CUT ${$cutNumber}</div>`
    //       : ""
    //   }`
    // );

    const templateRow =
      checkCurrentRound && +HQ.Properties.currentRound > 1
        ? nextRounds
        : firstRound;

    wrapper.insertAdjacentHTML("beforeend", templateRow);
  });

  return wrapper;
};

function thruColumnRounds(round) {
  if (!checkCurrentRound) return;

  // console.log(round, currentRound);
  HQ.Properties.currentRound = +HQ.Properties.currentRound;

  let stat = "";

  if (typeof round[HQ.Properties.currentRound] === "undefined") {
    stat = "";
  } else {
    let { status, holesplayed, startingtee, teetime } =
      round[HQ.Properties.currentRound];

    status = status === null ? "" : status;

    let $holesPlayed = null;
    let $startingTeeStar = "";
    let $startingTee = null;
    let $teetime = null;

    $holesPlayed = holesplayed;
    // Show a * for all matches not starting from Tee 1
    $startingTee = startingtee;
    if ($startingTee != 1) {
      $startingTeeStar = "*";
    }

    $teetime = teetime ? teetime.slice(0, 5) : "";

    if (Number(status) === 2) {
      stat = "F";
    } else if (Number(status) === 1) {
      if (teetime) {
        stat = `${$teetime}${$startingTeeStar}`;
      } else {
        stat = "";
      }
    } else {
      stat = `${$holesPlayed}${$startingTeeStar}`;
    }
  }

  return stat.trim();
}

/**
 * Find information in array HQ.Properties.layerFlights
 * depending on player id
 *
 * @param {number} id
 * @returns object
 */
function findLeaderboardInformation(id) {
  // console.log("playerID", id);
  // console.log("HQ.Properties.layerFlights", convertJSONToArray(HQ.Properties.layerFlights));

  const arrayLyaerFlights = convertJSONToArray(HQ.Properties.layerFlights);

  // console.log("HQ.Properties.layerFlights", arrayLyaerFlights);

  let flighsInformation = {};

  arrayLyaerFlights.forEach(
    ({
      status,
      players,
      score,
      teetime,
      holesplayed,
      matchnumber_str,
      startingtee,
      currenthole,
    }) => {
      // convert player to array
      const arrayPlayers = convertJSONToArray(players);

      // build object with status, startingtee, currenthole
      arrayPlayers.forEach((player) => {
        if (player.id === id) {
          flighsInformation.status = status;
          flighsInformation.score = score;
          flighsInformation.teetime = teetime;
          flighsInformation.matchnumber_str = matchnumber_str;
          flighsInformation.holesplayed = holesplayed;
          flighsInformation.startingtee = startingtee;
          flighsInformation.currenthole = currenthole;
        }
      });
    }
  );

  // console.log(flighsInformation);
  // return object with status, startingtee, currenthole
  return flighsInformation;
}

/**
 *
 * @param {string} totaltopar
 * @param {string} totaltopar_str
 */
function totaltoparInformation(totaltopar, totaltopar_str) {
  return totaltopar
    ? totaltopar < 0
      ? `<span class="text-danger">${totaltopar_str}</span>`
      : `<span class="text-success">${totaltopar_str}</span>`
    : totaltopar_str;
}

/**
 *
 * @param {array} arrayRest
 * @param {string} rest
 * @returns html
 */
function createHeader(arrayRest, rest) {
  const padding = rest === "firstRound" ? "p-2 " : "p-1 ";

  const headerRows = arrayRest
    .map(({ classNames, i18n }, index) => {
      const hqPropertiesI18n = HQ.Properties.i18n["leaderboard-header"][i18n];

      const headTitle = hqPropertiesI18n !== undefined ? hqPropertiesI18n : "";
      const iconPlayer =
        index === 1
          ? `<span class="d-flex player-name-info">(<span class="player-icon-header"><svg fill="#fff"><use href="/images/icons/sprite.svg#icon-flights"></use></svg></span> = playing)</span>`
          : "";
      return `
      <div class="${padding}${classNames}">
        <span>${headTitle}</span> ${iconPlayer}
      </div>`;
    })
    .join("");

  const fullTemplate = `<div class="row top-five-head py-1">${headerRows}</div>`;

  return fullTemplate;
}

// it top5 is not empty
if (
  typeof HQ.Properties.top5 !== "undefined" &&
  objectLength(HQ.Properties.top5) > 0
) {
  leaderboardContent.insertAdjacentElement("beforeend", leaderboardCreateRow());
}

if (HQ.Properties.top5 <= 0) {
  const text = `<div class="p-3">${HQ.Properties.i18n.leaderboard["no-data-available"]}</div>`;
  leaderboardContent.insertAdjacentHTML("beforeend", text);
}

let $selectedPlayers = [];

/**
 * Create row for leaderboard width favorite players
 */
function leaderBoardAddFavoritePlayer() {
  const topFiveDataID = selectAll(".top-five-wrapper > [data-id]");

  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");

  // add active row to favorite players
  [].slice
    .call(topFiveDataID)
    .filter((player) => getLocalStorage?.includes(+player.dataset.id))
    .map((item) => item.classList.add("active-row"));

  topFiveDataID.forEach((player) => {
    player.addEventListener("click", (e) => {
      const target = e.target;

      if (!target.classList.contains("icon-star")) return;

      // activeGroupIsideInformation(true);

      const row = target.closest(".row-item");
      // click on active row
      if (row.classList.contains("active-row")) {
        const id = row.dataset.id;

        const getAllID = selectAll(`div[data-id="${id}"]`);
        getAllID.forEach((item) => {
          // if row is data-fav remove row
          if (item.hasAttribute("data-fav")) {
            item.remove();
          } else {
            item.classList.remove("active-row");
          }
        });

        // remove id from localStorage
        removeIdFromLocalStorage(id);

        // hilight flightsmark the favored players
        markFavoritePlayers("update");
      } else {
        // get array from localStorage
        const getLocalStorage = getItemLocalStorage(storageName);
        // get id from row
        const id = row.dataset.id;

        $selectedPlayers = [];

        if (getLocalStorage) {
          // if local storage exist add id to array
          $selectedPlayers.push(...new Set(getLocalStorage));
        }
        // add id to array
        $selectedPlayers.push(+id);

        // add class active-row to row when click
        row.classList.add("active-row");

        // remove duplicates from array
        const newPlayers = [...new Set($selectedPlayers)];

        // create localStorage with new array
        setItemLocalStorage(storageName, newPlayers);

        // hilight flightsmark the favored players
        markFavoritePlayers("update");
      }
    });
  });
}

/**
 * Set new localstorage
 */
function removeIdFromLocalStorage(id) {
  const getLocalStorage = getItemLocalStorage("HQfavoritePlayers");
  const newLocalStorage = getLocalStorage.filter((x) => x !== Number(id));
  setItemLocalStorage(storageName, newLocalStorage);
}

export {
  leaderboardCreateRow,
  leaderBoardAddFavoritePlayer,
  leaderboardContent,
};
