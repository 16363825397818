const defaults = {
  iconSize: { w: 34, h: 34 },
  key: HQ.Properties.keyFormMap,
  coordinates: HQ.Properties.mapCoordinates,
  customLink: HQ.Properties.golfCourseTiles,
  customAttribution:
    'Event layer by <a href="https://www.eventhubhq.com/">EventhubHQ</a>',
  baseMapUrl: HQ.Properties.baseMapUrl,
  baseMapKey: HQ.Properties.baseMapKey,
  baseMapAttribution:
    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  location: {
    denied: `<h4>${HQ.Properties.i18n.location.denied.first}</h4><p>${HQ.Properties.i18n.location.denied.second}</p>`,
    unavailable: `<p>${HQ.Properties.i18n.location.unavailable}</p>`,
    timeout: `<p>${HQ.Properties.i18n.location.timeout}</p>`,
    error: `<p>${HQ.Properties.i18n.location.error}</p>`,
  },
};

export { defaults };
