import map from "../initialMap";
import {
  convertJSONToArray,
  cleareActiveSlider,
  compareTwoTimes,
  select,
  objectLength,
} from "../../utils/function";

import { analyticsTracking } from "../tracking";

const autocompleteObj = {
  showAllValues: true,
  classPreventClosing: "additional-elements",

  onSearch: ({ currentValue }) => {
    let globalArray = [];

    let data = convertJSONToArray(
      objectLength(HQ.Properties.layerFlights) > 0
        ? HQ.Properties.layerFlights
        : window["updateEntries"]
    );

    data.map(
      ({
        lat,
        lng,
        status,
        startingtee,
        currenthole,
        holesplayed,
        teetime,
        players,
        matchnumber_str,
        matchnumber,
      }) => {
        const newArray = convertJSONToArray(players);
        newArray.forEach((player) => {
          globalArray.push({
            ...player,
            lat,
            lng,
            status,
            teetime,
            startingtee,
            currenthole,
            matchnumber_str,
            holesplayed,
            flightNumber: matchnumber,
          });
        });
      }
    );

    const flatArray = globalArray.flat();

    return (
      flatArray
        // .sort((a, b) => a.fullname.localeCompare(b.fullname))
        .filter((element) => {
          return element.fullname.match(new RegExp(currentValue, "i"));
        })
    );
  },

  onRender: ({ results }) => {
    // console.log("results", results);

    results.insertAdjacentHTML(
      "beforebegin",
      `<ul class="auto-head">
        <li class="auto-row row py-1">
          <div class="p-1 col-6 auto-player-name d-flex align-items-center">
            <span class="player-name-header">${HQ.Properties.i18n["autocomplete"]["player"]}</span>
            <span class="d-flex player-name-info">
              (<span class="player-icon-header">
                <svg fill="#fff"><use href="/images/icons/sprite.svg#icon-flights"></use></svg>
              </span> = playing)
            </span>
          </div>
          <div class="p-1 col-1 text-center auto-tee">${HQ.Properties.i18n["autocomplete"].tee}</div>
          <div class="p-1 col-2 text-center auto-thru">${HQ.Properties.i18n["autocomplete"].thru}</div>
          <div class="p-1 col-2 text-center text-truncate auto-group">${HQ.Properties.i18n["autocomplete"].group}</div>
          <div class="p-1 col-1 text-center text-truncate auto-country"></div>
        </li>
      </ul>`
    );
  },

  onResults: ({ currentValue, matches, template }) => {
    const regex = new RegExp(currentValue, "gi");
    const autoHead = select(".auto-head");

    autoHead.classList[matches.length > 7 ? "add" : "remove"]("auto-pr");

    let $show = true;

    return matches === 0
      ? template
      : matches
          .map(
            (
              {
                id,
                status,
                teetime,
                country,
                flightNumber,
                matchnumber_str,
                startingtee,
                proam,
                currenthole,
                holesplayed,
                totaltopar,
                fullname,
                firstname,
                lastname,
              },
              index
            ) => {
              totaltopar = !totaltopar ? "PAR" : totaltopar;

              if (proam) {
                proam = proam === "am" ? "(A)" : "";
              } else {
                proam = "";
              }

              let previusFlightNumber = matches[index - 1]?.flightNumber;
              $show = previusFlightNumber === flightNumber ? false : true;

              const borderTop = $show ? "border-top" : "";

              // show group position
              const showGroupPosition = $show
                ? `<small>${flightNumber}</small>`
                : "";

              // show starting tee
              const showStartingtee = $show
                ? `<small>${startingtee}</small>`
                : "";

              // show time
              let $timeTemplate;

              if (status == "PLAYING") {
                let totaltoparTemplate;

                if (totaltopar < 0) {
                  totaltoparTemplate = '<span class="text-danger">';
                } else if (totaltopar > 0) {
                  totaltoparTemplate = '<span class="text-success">+';
                } else {
                  totaltoparTemplate = "";
                }

                // console.log("startingtee", startingtee);

                // #79 Renaming Player Search popup items
                const typeStartingtee = startingtee !== 1 ? "*" : "";

                $timeTemplate =
                  totaltoparTemplate +
                  totaltopar +
                  "</span> " +
                  holesplayed +
                  typeStartingtee;
              } else if (status == "FINISHED") {
                const timeTee = compareTwoTimes(teetime.slice(0, 5))
                  ? ""
                  : `<small>${teetime.slice(0, 5)} F</small>`;
                $timeTemplate = timeTee;
              } else {
                $timeTemplate = `<small>${teetime.slice(0, 5)}</small>`;
              }

              const flightnumber =
                flightNumber && currenthole
                  ? `data-id-flightnumber="${matchnumber_str}"`
                  : "";

              const flightIcons =
                status === "TEE" || status === "PLAYING"
                  ? `<span class="player-icon"><svg fill="#3e3e3e"><use href="/images/icons/sprite.svg#icon-flights"></use></svg></span>`
                  : "";

              let iconPlayer = "";
              if (status === "PLAYING" || status === "TEE") {
                iconPlayer = 'data-player-playing="true"';
              }

              return `
                <li ${flightnumber} class="row row-item ${borderTop} py-1 auto-row">
                  <div class="p-1 col-6 auto-player-name" ${iconPlayer}>
                    ${fullname.replace(
                      regex,
                      (str) => `<b style="color: #21325b;">${str}</b>`
                    )}
                    ${flightIcons}
                  </div>
                  <div class="p-1 col-1 text-center auto-tee">
                    ${showStartingtee}
                  </div>
                  <div class="p-1 col-2 text-center auto-thru">
                    ${$timeTemplate}
                  </div>
                  <div class="p-1 col-2 text-center auto-group">
                    ${showGroupPosition}
                  </div>
                  <div class="p-1 col-1 text-center auto-country">
                    <span title="${country}" class="flag-icon flag-icon-${country.toLowerCase()}"></span>
                  </div>
                </li>`;
            }
          )
          .join("");
  },

  onSubmit: ({ object }) => {
    // console.log("click on player", object);

    if (!object?.currenthole) return;

    let flightNumber = object.flightNumber;

    console.log("object", object.flightNumber);

    map.eachLayer((layer) => {
      const classArray = layer?._icon?.className.match(/\S+/g);

      const checkFlights = classArray?.includes(`flight-${flightNumber}`);

      // console.log(classArray);

      // console.log("checkFlights", checkFlights);

      if (layer instanceof L.Marker && checkFlights) {
        // close all sliders
        cleareActiveSlider();

        // fly to layer
        map.flyTo(layer.getLatLng(), map.getZoom());

        // add active class to layer
        layer._icon.classList.add("animation");

        // open popup layer
        layer.openPopup();
      }
    });

    // tracking event
    analyticsTracking("searchPlayerClick", { player: object.fullname });
  },

  onClose: () => {
    const searchPlayers = document.querySelector("#search-players");
    // const autoResultsWrapper = document.querySelector(".auto-results-wrapper");
    searchPlayers.click();
  },

  noResults: ({ element, template }) => {
    template(
      `<li class="row row-item py-1 auto-row">
        <div class="p-2 col-12 auto-not-found">No player found: "${element.value}"</div>
      </li>`
    );
  },
};

export default autocompleteObj;
